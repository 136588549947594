var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ticketBox
    ? _c(
        "el-dialog",
        {
          staticClass: "qmDialog",
          attrs: {
            title: "特殊作业选择",
            visible: _vm.ticketBox,
            width: "80%",
            top: "10vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.ticketBox = $event
            },
          },
        },
        [
          _c("grid-head-layout", {
            ref: "gridHeadLayout",
            attrs: { "search-columns": _vm.searchColumns },
            on: {
              "grid-head-search": _vm.gridHeadSearch,
              "grid-head-empty": _vm.gridHeadEmpty,
            },
          }),
          _c("grid-layout", {
            ref: "gridLayOut",
            attrs: {
              "table-options": _vm.option,
              "table-data": _vm.tableData,
              "table-loading": _vm.loading,
              "data-total": _vm.page.total,
              page: _vm.page,
            },
            on: {
              "page-current-change": _vm.onLoad,
              "page-size-change": _vm.onLoad,
              "gird-handle-select-click": _vm.selectionChange,
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确定")]
              ),
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn"))),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }