var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "region",
          attrs: {
            visible: _vm.dialogVisible,
            width: "60%",
            title: "区域选择",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-container",
            { staticStyle: { height: "100%" } },
            [
              _c("CommonTree", {
                ref: "commonTreeCore",
                attrs: {
                  treeTitle: "区域分类",
                  treeData: _vm.treeData,
                  nodeKey: "id",
                  searchTitle: "typeName",
                  defaultProps: { label: "typeName" },
                  expandOnClickNode: false,
                },
                on: { getNodeClick: _vm.handleNodeClick },
              }),
              _c(
                "el-main",
                [
                  _c("grid-head-layout", {
                    ref: "gridHeadLayout",
                    attrs: { "search-columns": _vm.searchColumns },
                    on: {
                      "grid-head-search": _vm.gridHeadSearch,
                      "grid-head-empty": _vm.gridHeadEmpty,
                    },
                  }),
                  _c("grid-layout", {
                    ref: "gridLayOut",
                    staticClass: "itemListGrid",
                    attrs: {
                      "table-options": _vm.tableOptions,
                      "table-data": _vm.tableData,
                      "table-loading": _vm.tableLoading,
                      "data-total": _vm.page.total,
                      page: _vm.page,
                    },
                    on: {
                      "page-current-change": _vm.handleCurrentChange,
                      "page-size-change": _vm.handleSizeChange,
                      "page-refresh-change": _vm.onLoad,
                      "gird-handle-select-click": _vm.selectionChange,
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "center",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-right": "24px" },
                          attrs: { size: "medium", type: "primary" },
                          on: { click: _vm.confirm },
                        },
                        [_vm._v("确认")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "medium" },
                          on: { click: _vm.closeDialog },
                        },
                        [_vm._v("取消")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }